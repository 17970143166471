const axios = require('axios');

export async function getTime() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GET_TIME_RV}/time`
    );
    const { datetime } = response.data;
    return datetime;
  } catch (error) {
    console.error('Erro ao obter o timestamp:', error.message);
  }
}
